<template>
    <div class="grid lg:grid-cols-12 md:mt-4 gap-12 py-8 overflow-hidden">
        <div class="col-span-6">
            <div> 
                <h2 class="text-3xl font-semibold tracking-tight text-blue-100 sm:text-17xl">
            About Signature Clinic
        </h2>
      <div class="max-w-xs md:max-w-xl">
        <p class="mt-5 text-18xl font-medium leading-6 text-blue md:max-w-xl w-full max-w-xl break-normal">
            We are thrilled to share that after careful consideration, we have matched you with Signature Clinic, a highly recommended establishment for your upcoming treatment.
        </p>
        <p class="mt-6 text-18xl font-medium leading-6 text-blue md:max-w-xl w-full max-w-xl break-normal">
            Anticipate a short call from Signature Clinic for your FREE telephone consultation. This session aims to provide comprehensive details about your desired treatment, addressing all your queries thoroughly.</p>
        <p class="mt-6 text-18xl font-medium leading-6 text-blue md:max-w-xl max-w-xs  break-normal">
            We want to highlight Signature Clinic’s exceptional reputation, boasting a stellar 5-star rating on Trustpilot. Discover others’ experiences and satisfaction by visiting - <br>
            <a class="underline text-18xl break-all" href="https://uk.trustpilot.com/review/signatureclinic.co.uk">
                https://uk.trustpilot.com/review/signatureclinic.co.uk
            </a> 
        </p>
        <p class="mt-6 text-18xl font-medium leading-6 text-blue md:max-w-xl w-full max-w-xl break-normal">
            Thank you for considering Signature Clinic. Their dedication to excellence assures a fulfilling experience.</p>
      </div>
            </div>
        </div>
        <div class="col-span-6">
            <!-- tabel -->
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-18xl text-left rtl:text-right text-blue dark:text-gray-400">
                <thead class="text-18xl text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-6 py-3">
                            Surgery name
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Price Guide
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td class="px-6 py-2">
                            Eyelid Reduction (Upper)
                        </td>
                        <td class="px-6 py-2">
                            £1,900
                        </td>
                    </tr>
                    <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td class="px-6 py-2">
                            Eyelid Reduction (Lower) 
                        </td>
                        <td class="px-6 py-2">
                            £2,400
                        </td>
                    </tr>
                    <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td class="px-6 py-2">
                            Eyelid Reduction (Upper & Lower)
                        </td>
                        <td class="px-6 py-2">
                            £3,700
                        </td>
                    </tr>
                    <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td class="px-6 py-2">
                            Face Lift (Full)
                        </td>
                        <td class="px-6 py-2">
                            £5,000
                        </td>
                    </tr>
                    <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td class="px-6 py-2">
                            Face Lift (Mini)
                        </td>
                        <td class="px-6 py-2">
                            £4,500
                        </td>
                    </tr>
                    <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td class="px-6 py-2">
                            Facial Fat Transfer
                        </td>
                        <td class="px-6 py-2">
                            £2,200 - £5000
                        </td>
                    </tr>
                    <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td class="px-6 py-2">
                            Labia Reduction
                        </td>
                        <td class="px-6 py-2">
                            £3,000 - £4,500
                        </td>
                    </tr>
                    <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td class="px-6 py-2">
                            Male Chest Reduction (Gynecomastia)
                        </td>
                        <td class="px-6 py-2">
                            £3,000 - £5,000
                        </td>
                    </tr>
                    <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td class="px-6 py-2">
                            Neck Lift
                        </td>
                        <td class="px-6 py-2">
                            £4,000 - £4,500
                        </td>
                    </tr>
                    <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td class="px-6 py-2">
                            Buccal Fat Pad Removal
                        </td>
                        <td class="px-6 py-2">
                            £2,299
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

            <!-- tabel -->
        </div>
    </div>
</template>

<script>
export default {
  name: 'PriceList',
}
</script>