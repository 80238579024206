<template>
    <footer class="text-blue-100 bg-gray-100 mx-auto pb-8 w-full">
       <div class="text-center font-normal text-sm md:text-9xl
       md:px-24 px-4 pt-8 sm:pb-8 lg:pb-8" id="date">
        ©{{ currentYear }} <router-link to="/">
          cosmeticsurgerynearme.uk</router-link>
        is a trading style of Pivotal One Ltd. Registered Address: 112 Bolling Road, Ilkley, LS29 8PN.
<br> Company Number: 11698188. Vat Number: 316 8795 64
    | <a href="tel:0161 660 9222">Tel: 0161 660 9222.</a>
    Registered with the Information Commissioners Office: ZA478297. <br>
    <router-link class="text-blue-100" :to="{ path: 'privacy' }">Privacy Policy</router-link> |
    <router-link class="text-blue-100" :to="{ path: 'terms' }">Terms of website</router-link>
</div>
</footer>
</template>
<!-- /* eslint-disable */ -->
<script>
export default {
  name: 'FooterPage',
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
