<template>

  <div class="md:pb-20 pb-16 text-white bg-blue-100">

    <div class="flex justify-center md:text-2xl text-xl
    px-10 font-bold md:pt-16 md:pb-12 py-14 text-center">What our clients say about us</div>

    <div

      class="w-full h-full md:mt-5  owl-carousel owl-theme owl-loaded owl-drag"

    >

      <div

        v-for="(item, index) in carouselItems"

        :key="index"

        class="p-8 text-blue-100 bg-white item md:mr-4 mr-2 rounded-lg md:h-80 h-96"

      >
      <svg xmlns="http://www.w3.org/2000/svg" width="107" height="20" viewBox="0 0 107 20" fill="none">
      <g clip-path="url(#clip0_22_4414)">
        <path d="M20 0H0V20H20V0Z" fill="#00B67A"/>
        <path d="M41.6667 0H21.6667V20H41.6667V0Z" fill="#00B67A"/>
        <path d="M63.3333 0H43.3333V20H63.3333V0Z" fill="#00B67A"/>
        <path d="M85 0H65V20H85V0Z" fill="#00B67A"/>
        <path d="M106.667 0H86.6667V20H106.667V0Z" fill="#00B67A"/>
        <path d="M10 13.4792L13.0417 12.7083L14.3125 16.625L10 13.4792ZM17
        8.41667H11.6458L10 3.375L8.35417 8.41667H3L7.33333 11.5417L5.6875
        16.5833L10.0208 13.4583L12.6875 11.5417L17 8.41667Z" fill="white"/>
        <path d="M31.6667 13.4792L34.7084 12.7083L35.9792 16.625L31.6667
        13.4792ZM38.6667 8.41667H33.3126L31.6667 3.375L30.0209 8.41667H24.6667L29.0001
        11.5417L27.3542 16.5833L31.6876 13.4583L34.3542 11.5417L38.6667 8.41667Z" fill="white"/>
        <path d="M53.3333 13.4792L56.3749 12.7083L57.6458 16.625L53.3333 13.4792ZM60.3333
        8.41667H54.9791L53.3333 3.375L51.6874 8.41667H46.3333L50.6666 11.5417L49.0208
        16.5833L53.3541 13.4583L56.0208 11.5417L60.3333 8.41667Z" fill="white"/>
        <path d="M75 13.4792L78.0417 12.7083L79.3125 16.625L75 13.4792ZM82 8.41667H76.6458L75
        3.375L73.3542 8.41667H68L72.3333 11.5417L70.6875 16.5833L75.0208 13.4583L77.6875
        11.5417L82 8.41667Z" fill="white"/>
        <path d="M96.6667 13.4792L99.7084 12.7083L100.979 16.625L96.6667 13.4792ZM103.667
        8.41667H98.3126L96.6667 3.375L95.0209 8.41667H89.6667L94.0001 11.5417L92.3542 16.5833L96.6876
        13.4583L99.3542 11.5417L103.667 8.41667Z" fill="white"/>
      </g>
  <defs>
    <clipPath id="clip0_22_4414">
      <rect width="106.667" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>
       <div><p class="text-xl font-bold pt-5">{{ item.tag }}</p></div>
       <div><p class="text-9xl font-normal text-blue-100 pt-2 pb-5
        tracking-snug">{{ item.comment }}</p></div>
       <div class="font-bold text-10xl text-blue-100">{{ item.author }}</div>

      </div>

    </div>

    <div class="flex items-center justify-center mt-16 space-x-10">

      <span class="text-green-100 cursor-pointer customPrevBtn bg-white rounded-full p-1
      text-base font-bold">

        <!-- <i class="bi bi-chevron-left font-bold"></i> -->
        <svg xmlns="http://www.w3.org/2000/svg"  width="40"
        height="41" viewBox="0 0 40 41" fill="none">
          <path d="M25 10.1658L15 20.1658L25 30.1658" stroke="#25E09E"
          stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

      </span>

      <div class="md:flex items-center space-x-2 custom-dots-container hidden">
        <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
        <div

          v-for="(item, index) in dots"

          :key="index"

          class="cursor-pointer custom-dot"

          :data-index="index"

          @click="goToSlide(index)"

          :class="{ active: currentIndex === index }"

        >

          <i class="bi bi-circle-fill"></i>

        </div>

      </div>

      <span class="text-green-100 cursor-pointer customNextBtn bg-white rounded-full p-1">

        <!-- <i class="bi bi-chevron-right"></i> -->
        <svg xmlns="http://www.w3.org/2000/svg" width="40"
        height="41" viewBox="0 0 40 41" fill="none">
          <path d="M15 30.1658L25 20.1658L15 10.1658"
          stroke="#25E09E" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

      </span>

    </div>

  </div>

</template>

<style>

.owl-dots {

  display: none;

}

.active {

  color: #25E09E;

}

</style>
<!-- eslint-disable -->
<script>

export default {

  data() {
    return {

      currentIndex: 0,

      carouselItems: [

        { 
          images: 'images/logos/stars-5.svg',
          tag: 'Professional service',
          comment: 'Very professional from the outset, I had great advise from beginning to end and was made to feel at ease, I would 100% recommend them',
          author: 'Julia Wright',
        },

        { 
          images: 'images/logos/stars-5.svg',
          tag: 'Exceptional Aftercare!',
          comment: 'Surgeon made me feel very reassured as did the nursing staff during the procedure, now a year post op I am really enjoying the results. Looking forward to for many years to come.After care was excellent always on hand when I needed.',
          author: 'Lesley Messenger Jones', 
         },

        { 
          images: 'images/logos/stars-5.svg',
          tag: 'Best service',
          comment: 'The service and care I received was 10/10 from my video consultation to my weekly phone check ups after my surgery. Dr Ahmed was great and made me really comfortable. His assistants were also attentive and my experience great. I’ve been left with no serious scarring and a look 10x than I imagined Can’t recommend enough',
          author: 'Zac Wakefield', 
         },

        { 
          images: 'images/logos/stars-5.svg',
          tag: 'Fantastic!',
          comment: 'Fantastic, professional service from start to finish. I had an upper blepharoplasty and cannot believe how straightforward to whole process was. I was immediately put at ease from arrival at the clinic and the staff and surgeon were so friendly and professional. Down time after the surgery was minimal and I am thrilled with the results.',
          author: 'Kelly Heyworth', 
         },

        { 
          images: 'images/logos/stars-5.svg',
          tag: 'Outstanding Service!',
          comment: 'The service i received from day one has been outstanding, from the girls on the phone, to the aftercare team and of course Dr Ahmed. I had lower eyelid Blepharoplasty, where they removed the fatty tissue and excess bags, its taken 10 years off me, and Im really happy with the outcome, just wish I had it done sooner.',
          author: 'Alex Rumsey',
         },

        // Add more items as needed

      ],

      dots: [],

    };
  },

  mounted() {
    const owl = $('.owl-carousel');

    owl.owlCarousel({

      loop: true,

      margin: 15,

      stagePadding: 20,

      dots: true,

      autoPlay: true,

      responsive: {

        0: {

          items: 1,

        },

        600: {

          items: 1,

        },

        1000: {

          items: 3,

        },

      },

    });

    owl.on('changed.owl.carousel', (event) => {
      this.currentIndex = event.page.index;
    });

    const dotNums = owl.find('.owl-dot').length;

    this.dots = Array(dotNums).fill(0);

    $('.custom-dot:first-child').addClass('active');

    $('.customNextBtn').click(() => {
      owl.trigger('next.owl.carousel');
    });

    $('.customPrevBtn').click(() => {
      owl.trigger('prev.owl.carousel', [300]);
    });
  },

  methods: {

    goToSlide(index) {
      const owl = $('.owl-carousel');

      owl.trigger('to.owl.carousel', [index, 300]);
    },

  },

};

</script>
