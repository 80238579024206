<template>
  <div class="relative overflow-hidden">
  <HeaderPage/>
  <main class="relative isolate overflow-hidden bg-gray-900  pt-10 pb-16 md:pb-20 md:pt-10"
  ref="targetRef">
    <img src="images/banner-img.png" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover md:block hidden">
    <img src="images/banner-mobile.png" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover block md:hidden">
    <div class="absolute inset-0 bg-black opacity-50 sm:w-72 sm:h-72 md:w-0 mx-auto"></div> 
    <div class="relative mx-auto max-w-7xl px-6 lg:px-18 md:pl-10">
      <div class="absolute -bottom-8 -left-96 -z-10 transform-gpu blur-3xl
      sm:-bottom-64 sm:-left-40 lg:-bottom-32 lg:left-8 xl:-left-10" aria-hidden="true">
      </div>
      <h1 class="text-15xl md:mt-2 md:pt-2  font-bold tracking-light leading-tight
    text-white sm:text-5xl text-left max-w-xl">
    Find the best  Cosmetic   clinics in your area </h1>
       <p class="text-white md:max-w-lg md:py-6 py-3 text-10xl md:text-sm
       md:leading-6 md:font-normal font-medium">Find specialists in Blepharoplasty, Face Lifts, Neck Lifts, Labiaplasty, Gynecomastia, Brow Lifts and Buccal Fat Pad Removal. Choose the best
       cosmetic clinic for you,<span class="font-bold"> view their prices online and book a free consultation.</span> </p>
          <div class="relative lg:flex lg:items-center">
    <form id="formSection" class="relative" autocomplete="off" @submit.prevent="submitForm" novalidate>
      <div v-if="errorMessage" class="text-white py-0.5 px-4 text-sm bg-red-500 rounded-sm">{{ errorMessage }}</div>
              <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
              <label for="postalCode" class="sr-only">Postcode</label>
            <div>
      <div class="mt-2 lg:flex hidden rounded-md shadow-sm">
        <div class="relative flex flex-grow items-stretch focus-within:z-10">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <img class="z-20 h-5 w-5" src="images/icons/map-marker-alt-solid.svg.svg" alt="">
          </div>
          <input
            v-model="postalCode"
            id="postalCode"
            type="text"
            pattern="^[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]?\s?[0-9][A-Za-z]{2}$"
            required
          class="block w-full rounded-none rounded-l-md border-0
          py-4 pl-10 text-gray-900
          focus:outline-none placeholder-gray-800 text-xs md:text-sm sm:leading-6"
          :class="{ ' focus:ring-2 focus:ring-inset focus:ring-red-500': errorMessage !== '' }"
          placeholder="Enter Postcode" />
        </div>
        <button type="submit" class="relative -ml-px inline-flex  items-center
        gap-x-1.5 rounded-r-md px-3 py-2 text-xs md:text-sm font-semibold text-white bg-green-100">
          Find a cosmetic clinic
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
        class="-ml-0.5 h-5 w-5 text-white">
                    <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168
                    10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75
                    0 01-1.06-.02z"
                    clip-rule="evenodd" />
        </svg>
        </button>
      </div>
    </div>
    <div class="lg:hidden space-y-5">
    <div>
        <div class="relative mt-2">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <img class="z-20 h-5 w-5" src="images/icons/map-marker-alt-solid.svg.svg" alt="">
          </div>
            <input v-model="postalCode"
            id="postalCode"
            type="text"
            pattern="[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]? [0-9][A-Za-z]{2}"
            required
            placeholder="Enter Postcode"
            class="block w-full rounded-md border-0 py-4 pl-10
            text-gray-900
            placeholder:text-gray-800 sm:text-sm sm:leading-6">
          </div>
        </div>
  
        <div>
          <button type="submit" class="mt-6 flex w-full justify-center rounded-md
          px-3 py-4 text-sm font-semibold text-white bg-green-100 leading-6
          shadow-sm focus:outline-none">Find a cosmetic clinic</button>
        </div>
  </div>
            </form>
  </div>
    </div>
  </main>
  <section>
    <div class="bg-white md:py-14 py-10">
    <div class="mx-auto max-w-7xl px-4 md:px-4 lg:px-0">
      <!-- <div class="grid px-6 md:grid-cols-2 lg:grid-cols-6"> 
        <img class=" max-h-12 w-full object-contain lg:col-span-1"
        src="images/logos/General_Medical_Council_logo.svg.png"
        alt="General_Medical_Council_logo" width="158" height="48">
        <img class=" max-h-12 w-full object-contain lg:col-span-1"
        src="images/logos/1280px-Care_Quality_Commission_logo.svg.png"
        alt="Care_Quality_Commission_logo" width="158" height="48">
        <img class=" max-h-12 w-full object-contain lg:col-span-1"
        src="images/logos/british-medical-association-bma-logo-vector.png"
        alt="british-medical-association-bma-logo-vector" width="158" height="48">
        <img class=" max-h-12 w-full object-contain sm:col-start-2
        lg:col-span-1" src="images/logos/HIS-Logo.jpg"
        alt="HIS-Logo" width="158" height="48">
        <img class=" col-start-2 max-h-12 w-full object-contain
        sm:col-start-auto lg:col-span-1"
        src="images/logos/74d6b9aaf75d4314975c2b3c07e3b8bc_BCAM-logo.png"
        alt="BCAM-logo" width="158" height="48">
        <img class=" col-start-2 max-h-12 w-full object-contain
        sm:col-start-auto lg:col-span-1"
        src="images/logos/download.png"
        alt="-logo" width="158" height="48">
        <img class=" col-start-2 max-h-12 w-full object-contain
        sm:col-start-auto lg:col-span-1"
        src="images/logos/obagi-logo.png"
        alt="obagi logo" width="158" height="48">
      </div> -->
      <div class="mx-auto grid max-w-lg grid-cols-3 items-center gap-x-10 gap-y-10 sm:max-w-7xl sm:grid-cols-5 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-7 lg:px-8">
      <div class="mx-auto md:mb-12 lg:mb-0">
        <img src="images/logos/General_Medical_Council_logo.svg.png"
          class="max-w-[100px] dark:brightness-150" />
      </div>

      <div class="mx-auto md:mb-12 lg:mb-0">
        <img src="images/logos/1280px-Care_Quality_Commission_logo.svg.png"
          class="max-w-[100px] dark:brightness-150" />
      </div>

      <div class="mx-auto md:mb-12 lg:mb-0">
        <img src="images/logos/british-medical-association-bma-logo-vector.png"
          class="max-w-[100px] dark:brightness-150" />
      </div>

      <div class="mx-auto md:mb-12 lg:mb-0">
        <img src="images/logos/HIS-Logo.jpg"
          class="max-w-[100px] dark:brightness-150" />
      </div>

      <div class="mx-auto md:mb-12 lg:mb-0">
        <img src="images/logos/download.png"
          class="max-w-[100px] dark:brightness-150" />
      </div>

      <div class="mx-auto md:mb-12 lg:mb-0 md:hidden lg:block ">
        <img src="images/logos/74d6b9aaf75d4314975c2b3c07e3b8bc_BCAM-logo.png"
          class="max-w-[100px] dark:brightness-150" />
      </div>

      <div class="mx-auto md:mb-12 lg:mb-0 hidden md:hidden lg:block">
        <img src="images/logos/obagi-logo.png"
          class="max-w-[100px] dark:brightness-150" />
      </div>
    </div>
    </div>
  </div>
  </section>
    <section class="bg-gray-100">
      <div class="lg:px-24 px-4 py-14 md:py-24 lg:py-14">
        <div class="grid lg:grid-cols-3 md:grid-cols-1 gap-14">
          <div>
            <img src="images/icons/Frame 3.svg" alt="location icon">
            <div>
              <h2 class="font-bold md:text-3xl text-14xl
              text-blue-100 mt-6">Local Clinics Near You</h2>
              <p class="text-blue-100 text-sm md:py-6 py-2 tracking-normal font-medium">
                We have helped thousands of customers connect to the best cosmetic surgery clinics
                local to London, Manchester, Birmingham, Liverpool, Glasgow and Cardiff.
                Open 7 days a week, all of our
                clinics offer a wide range of treatments provided by highly
                experienced GMC Registered surgeons.
              </p>
            </div>
          </div>
          <!-- ... -->
          <div>
            <img src="images/icons/Frame 2.svg" alt="thumbs-up icon">
            <div>
              <h2 class="font-bold md:text-3xl text-14xl
              text-blue-100 mt-6">Free Consultations</h2>
              <p class="text-blue-100 text-sm md:py-6 py-2 tracking-normal font-medium">
                All of our clinics provide patients with free consultations which are
                carried out in a manner
                which is individual to the patient’s needs.
                Patient involvement, respect and confidentiality
                are priorities during all consultations as
                our surgeons strive for outcomes consistent with your
                expectations and best practice guidelines.
              </p>
            </div>
          </div>
          <div>
            <img src="images/icons/Frame 4.svg" alt="">
          <h2 class="font-bold md:text-3xl text-14xl
          text-blue-100 mt-6">The UK’s Best Surgeons</h2>
          <p class="text-blue-100 text-sm md:py-6 py-2 tracking-normal font-medium">
            All surgeons and clinics have been thoroughly vetted and hand-selected; based
            on their extensive experience in their respective cosmetic surgery specialisms,
            their wealth of qualifications and memberships with the appropriate healthcare
            bodies. Rest assured you are in the best of hands.
          </p>
        </div>
        </div>
      </div>
    </section>
    <section class="lg:px-24 px-4 py-10 sm:py-14 lg:py-32  mx-auto relative">
      <div class="block lg:hidden">
      <div class="py-8">
      <img src="images/Group 20.png"
      class="block h-full w-full rounded-lg object-cover object-center ml-3" alt="cosmetic surgery
      in progress">
      </div>
     </div>
      <div class="lg:flex lg:justify-between lg:gap-20 ">
        <div>
      <div
      class="font-bold md:text-4xl text-xl text-left px-4 md:px-0 lg:mt-6">
      Why Cosmetic Surgery Near Me
    </div>
      <ul class="py-8 grid grid-cols-1 md:gap-x-8 md:gap-y-5 gap-3 md:mt-6 max-w-6xl">
        <li class="flex md:gap-6 gap-4 items-center">
            <img class="w-10 h-10" src="images/icons/check.svg" alt="">
              <div>
                  <h3 class="font-semibold md:text-base text-sm">
                    Access to the exclusive prices with no hidden fees
                  </h3>
              </div>
        </li>
        <li class="flex md:gap-6 gap-4 items-center">
            <img class="w-10 h-10" src="images/icons/check.svg" alt="">
              <div>
                  <h3 class="font-semibold md:text-base text-sm">
                    Enjoy peace of mind with GMC Registered surgeons
                  </h3>
              </div>
        </li>
        <li class="flex md:gap-6 gap-4 items-center">
            <img class="w-10 h-10" src="images/icons/check.svg" alt="">
              <div>
                  <h3 class="font-semibold md:text-base text-sm">
                    0% interest-free finance available on all treatments
                  </h3>
              </div>
        </li>
        <li class="flex md:gap-6 gap-4 items-center">
            <img class="w-10 h-10" src="images/icons/check.svg" alt="">
              <div>
                  <h3 class="font-semibold md:text-base text-sm">
                    Free consultations with no obligation to proceed
                  </h3>
              </div>
        </li>
        <li class="flex md:gap-6 gap-4 items-center">
            <img class="w-10 h-10" src="images/icons/check.svg" alt="">
              <div>
                  <h3 class="font-semibold md:text-base text-sm">
                    Online consultations are now also available
                  </h3>
              </div>
        </li>
        <li class="flex md:gap-6 gap-4 items-center">
            <img class="w-10 h-10" src="images/icons/check.svg" alt="">
              <div>
                  <h3 class="font-semibold md:text-base text-sm">
                    Free aftercare and follow-up appointments for life
                  </h3>
              </div>
        </li>
      </ul>
      </div>
     <div class="lg:block hidden mt-2">
      <div>
      <img class="max-w-xl" src="images/Group 20.png" alt="cosmetic surgery image">
      </div>
     </div>
      </div>
    </section>
    <section class="lg:px-24  mx-auto">
      <div class="bg-blue-100 md:rounded-md md:pt-16 md:pb-10 pt-4 pb-4 md:px-16 relative ">
          <div class="px-8 md:px-0 py-12 md:py-0">
             <div class="md:space-y-2">
              <div class="text-white font-bold md:text-6xl text-xl max-w-lg leading-tight
              md:text-left text-left">
                Find the best cosmetic Surgeon in your area.
              </div>
              <div class="text-white  text-sm font-normal max-w-lg mt-4 md:mt-0
              md:text-left text-left">
              Get a FREE consultation today and get matched with the best clinic, and view prices of your surgery online.
              </div>
  
              <div class="grid grid-cols-1 gap-3 space-y-2 text-white
              font-bold max-w-lg md:pt-4 md:text-left text-left mt-8">
                  <ul class="grid md:grid-cols-2 lg:grid-cols-2 gap-4  pt-1">
                   <li><router-link :to="{ path: 'get-started' }" class="text-9xl md:text-18xl">Blepharoplasty (Eyelids)</router-link></li> 
                  <li><router-link :to="{ path: 'get-started' }" class="text-9xl md:text-18xl">Gynecomastia (Male chest)</router-link></li>
                  <li><router-link :to="{ path: 'get-started' }" class="text-9xl md:text-18xl">Face Lift</router-link></li>
                  <li><router-link :to="{ path: 'get-started' }" class="text-9xl md:text-18xl">Neck Lift</router-link></li>
                  <li><router-link :to="{ path: 'get-started' }" class="text-9xl md:text-18xl">Brow Lift</router-link></li>
                  <li><router-link :to="{ path: 'get-started' }" class="text-9xl md:text-18xl">Labiaplasty</router-link></li>
                  <li><router-link :to="{ path: 'get-started' }" class="text-9xl md:text-18xl">Buccal Fat Pad Removal</router-link></li>
                  </ul>
                  <div class="flex justify-start pt-2">
                    <div class="block">
                      <router-link :to="{ path: 'get-started' }" type="button" 
                      class="my-8 focus:outline-none text-white bg-green-100
                      font-semibold rounded-lg text-sm px-5 py-4 md:me-2 mb-2 mx-auto w-full md:w-auto">
                      Find a cosmetic clinic
                      </router-link>
                    </div>
                  </div>
                  <!-- <div class="text-9xl md:text-18xl">Buccal Fat Pad Removal</div> -->
              </div>
             </div>
             <!-- <div class="mx-auto block md:mb-8">
              <router-link :to="{ path: 'get-started' }" type="button" 
              class="my-8 focus:outline-none text-white bg-green-100
              font-semibold rounded-lg text-sm px-5 py-4 md:me-2 mb-2 mx-auto w-full md:w-auto">
              Find a cosmetic clinic
              </router-link>
             </div> -->
          </div>
  
          <div class="absolute inset-y-0 right-12 -mt-14 -mr-4 w-auto lg:block hidden">
              <img src="images/Ellipse 12.png" class="mt-4 object-fit" alt="woman using an ipad">
          </div>
      </div>
  </section>
  <section>
    <div class="lg:px-36 py-14 px-4 sm:pt-28 lg:pt-28  mx-auto">
      <h3 class="text-center md:text-2xl text-xl font-bold md:py-4 text-blue-100">How it works</h3>
      <div class="grid grid-cols-1 md:grid md:grid-cols-2 lg:grid-cols-3 lg:gap-24 md:gap-12 py-8">
        <div>
          <img class="object-scale-down md:w-72 w-52 flex justify-center mx-auto"
           src="images/Section → List → Item → Figure(3).png" alt="doctor">
          <div class="flex gap-8 py-8">
         <span class="text-blue-200 text-16xl md:text-8xl font-bold">1.</span>
              <div>
                  <h3 class="font-normal  text-sm md:w-72 tracking-tight">
                    Let us know what type of surgery you are interested in by completing
                    our simple online form.
                  </h3>
              </div>
            </div>
        </div>
        <div>
          <img class="object-scale-down md:w-72 w-52 flex justify-center mx-auto"
           src="images/Section → List → Item → Figure.png" alt="group of people">
          <div class="flex gap-8 py-8">
         <span class="text-blue-200 text-16xl md:text-8xl font-bold">2.</span>
              <div>
                  <h3 class="font-normal  text-sm md:w-72 tracking-tight">
                    We match you with the best clinics near you for a
                    free consultation to discuss your requirements
                  </h3>
              </div>
            </div>
        </div>
        <div>
          <img class="object-scale-down md:w-72 w-52 flex justify-center mx-auto"
           src="images/Section → List → Item → Figure(4).png" alt="">
          <div class="flex gap-8 py-8">
         <span class="text-blue-200 text-16xl md:text-8xl font-bold">3.</span>
              <div>
                  <h3 class="font-normal  text-sm md:w-72 tracking-tight">
                    Choose the best option for you, in full confidence that
                    if you do proceed you are in the best of hands
                  </h3>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>
  
  <section>
    <div class="md:px-20 lg:px-24 px-4 pb-14 sm:pb-20 lg:pb-20">
     <div class="flex justify-center max-w-7xl">
      <h3 class="text-center md:text-2xl text-xl font-bold w-full max-w-2xl">
      Find the nearest cosmetic surgery clinic to you
    </h3>
  </div>
      <div class="grid grid-cols-2 md:gap-10 gap-4 sm:grid-cols-2 lg:grid-cols-3 md:py-10 py-4 md:mt-4
      ">
        <router-link :to="{ path: 'get-started' }" class="relative text-center col-span-1">
          <img
          class="object-cover object-center mx-auto "
          src="images/cities/birmingham.jpg" alt="">
          <div class="absolute inset-0 bg-black opacity-50 mx-auto"></div>
          <svg xmlns="http://www.w3.org/2000/svg"
          class="mt-6 absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 hidden md:block
          pb-4" width="35" height="46" viewBox="0 0 35 46" fill="none">
            <path d="M17.4999 0.834229C13.108 0.834229 8.89585 2.57894 5.79025
            5.68454C2.68465 8.79014 0.939941 13.0022 0.939941 17.3942C0.939941 27.5642
            10.4199 39.5042 15.0299 44.7242C15.3396 45.0737 15.7199 45.3535 16.1456
            45.5451C16.5714 45.7367 17.033 45.8358 17.4999 45.8358C17.9669 45.8358 18.4285
            45.7367 18.8542 45.5451C19.28 45.3535 19.6603 45.0737 19.9699 44.7242C24.5899
            39.5042 34.0599 27.5642 34.0599 17.3942C34.0599 15.2195 33.6316 13.0661 32.7994
            11.057C31.9672 9.04784 30.7474 7.22228 29.2096 5.68454C27.6719 4.1468 25.8463 2.927
            23.8372 2.09478C21.828 1.26257 19.6746 0.834229 17.4999 0.834229ZM17.4999 25.9742C15.803
            25.9742 14.1441 25.471 12.7332 24.5282C11.3222 23.5855 10.2225 22.2454 9.57306
            20.6777C8.92366 19.1099 8.75374 17.3847 9.0848 15.7204C9.41587
            14.056 10.233 12.5272 11.433
            11.3273C12.6329 10.1273 14.1617 9.31015 15.8261 8.97909C17.4904
            8.64803 19.2156 8.81794 20.7834
            9.46734C22.3512 10.1167 23.6912 11.2165 24.634 12.6274C25.5767
            14.0384 26.0799 15.6973 26.0799
            17.3942C26.0799 19.6698 25.176 21.8521 23.5669 23.4612C21.9579
            25.0703 19.7755 25.9742 17.4999
            25.9742Z" fill="white"/>
            </svg>
          <h5 class="md:mt-5 absolute md:text-xl text-sm font-semibold text-white/100
          top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            Birmingham</h5>
        </router-link>
        <router-link :to="{ path: 'get-started' }" class="relative text-center col-span-1">
          <img class="object-cover object-center
          mx-auto" src="images/cities/cardiff.jpg" alt="">
          <div class="absolute inset-0 bg-black opacity-50 mx-auto"></div>
          <svg xmlns="http://www.w3.org/2000/svg"
          class="mt-6 absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 hidden md:block
          pb-4" width="35" height="46" viewBox="0 0 35 46" fill="none">
            <path d="M17.4999 0.834229C13.108 0.834229 8.89585 2.57894 5.79025
            5.68454C2.68465 8.79014 0.939941 13.0022 0.939941 17.3942C0.939941 27.5642
            10.4199 39.5042 15.0299 44.7242C15.3396 45.0737 15.7199 45.3535 16.1456
            45.5451C16.5714 45.7367 17.033 45.8358 17.4999 45.8358C17.9669 45.8358 18.4285
            45.7367 18.8542 45.5451C19.28 45.3535 19.6603 45.0737 19.9699 44.7242C24.5899
            39.5042 34.0599 27.5642 34.0599 17.3942C34.0599 15.2195 33.6316 13.0661 32.7994
            11.057C31.9672 9.04784 30.7474 7.22228 29.2096 5.68454C27.6719 4.1468 25.8463 2.927
            23.8372 2.09478C21.828 1.26257 19.6746 0.834229 17.4999 0.834229ZM17.4999 25.9742C15.803
            25.9742 14.1441 25.471 12.7332 24.5282C11.3222 23.5855 10.2225 22.2454 9.57306
            20.6777C8.92366 19.1099 8.75374 17.3847 9.0848 15.7204C9.41587
            14.056 10.233 12.5272 11.433
            11.3273C12.6329 10.1273 14.1617 9.31015 15.8261 8.97909C17.4904
            8.64803 19.2156 8.81794 20.7834
            9.46734C22.3512 10.1167 23.6912 11.2165 24.634 12.6274C25.5767
            14.0384 26.0799 15.6973 26.0799
            17.3942C26.0799 19.6698 25.176 21.8521 23.5669 23.4612C21.9579
            25.0703 19.7755 25.9742 17.4999
            25.9742Z" fill="white"/>
            </svg>
          <h5 class="md:mt-5 absolute md:text-xl text-sm font-semibold text-white/100
          top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            Cardiff</h5>
        </router-link>
        <router-link :to="{ path: 'get-started' }" class="relative text-center col-span-1">
          <img class="object-cover object-center
          mx-auto" src="images/cities/glasgow.jpg" alt="">
          <div class="absolute inset-0 bg-black opacity-50 mx-auto"></div>
          <svg xmlns="http://www.w3.org/2000/svg"
          class="mt-6 absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 hidden md:block
          pb-4" width="35" height="46" viewBox="0 0 35 46" fill="none">
            <path d="M17.4999 0.834229C13.108 0.834229 8.89585 2.57894 5.79025
            5.68454C2.68465 8.79014 0.939941 13.0022 0.939941 17.3942C0.939941 27.5642
            10.4199 39.5042 15.0299 44.7242C15.3396 45.0737 15.7199 45.3535 16.1456
            45.5451C16.5714 45.7367 17.033 45.8358 17.4999 45.8358C17.9669 45.8358 18.4285
            45.7367 18.8542 45.5451C19.28 45.3535 19.6603 45.0737 19.9699 44.7242C24.5899
            39.5042 34.0599 27.5642 34.0599 17.3942C34.0599 15.2195 33.6316 13.0661 32.7994
            11.057C31.9672 9.04784 30.7474 7.22228 29.2096 5.68454C27.6719 4.1468 25.8463 2.927
            23.8372 2.09478C21.828 1.26257 19.6746 0.834229 17.4999 0.834229ZM17.4999 25.9742C15.803
            25.9742 14.1441 25.471 12.7332 24.5282C11.3222 23.5855 10.2225 22.2454 9.57306
            20.6777C8.92366 19.1099 8.75374 17.3847 9.0848 15.7204C9.41587
            14.056 10.233 12.5272 11.433
            11.3273C12.6329 10.1273 14.1617 9.31015 15.8261 8.97909C17.4904
            8.64803 19.2156 8.81794 20.7834
            9.46734C22.3512 10.1167 23.6912 11.2165 24.634 12.6274C25.5767
            14.0384 26.0799 15.6973 26.0799
            17.3942C26.0799 19.6698 25.176 21.8521 23.5669 23.4612C21.9579
            25.0703 19.7755 25.9742 17.4999
            25.9742Z" fill="white"/>
            </svg>
          <h5 class="md:mt-5 absolute md:text-xl text-sm font-semibold text-white/100
          top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            Glasgow</h5>
        </router-link>
        <router-link :to="{ path: 'get-started' }" class="relative text-center col-span-1">
          <img class=" object-cover object-center
          mx-auto" src="images/cities/liverpool.jpg" alt="">
          <div class="absolute inset-0 bg-black opacity-50 mx-auto"></div>
          <svg xmlns="http://www.w3.org/2000/svg"
          class="mt-6 absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 hidden md:block
          pb-4" width="35" height="46" viewBox="0 0 35 46" fill="none">
            <path d="M17.4999 0.834229C13.108 0.834229 8.89585 2.57894 5.79025
            5.68454C2.68465 8.79014 0.939941 13.0022 0.939941 17.3942C0.939941 27.5642
            10.4199 39.5042 15.0299 44.7242C15.3396 45.0737 15.7199 45.3535 16.1456
            45.5451C16.5714 45.7367 17.033 45.8358 17.4999 45.8358C17.9669 45.8358 18.4285
            45.7367 18.8542 45.5451C19.28 45.3535 19.6603 45.0737 19.9699 44.7242C24.5899
            39.5042 34.0599 27.5642 34.0599 17.3942C34.0599 15.2195 33.6316 13.0661 32.7994
            11.057C31.9672 9.04784 30.7474 7.22228 29.2096 5.68454C27.6719 4.1468 25.8463 2.927
            23.8372 2.09478C21.828 1.26257 19.6746 0.834229 17.4999 0.834229ZM17.4999 25.9742C15.803
            25.9742 14.1441 25.471 12.7332 24.5282C11.3222 23.5855 10.2225 22.2454 9.57306
            20.6777C8.92366 19.1099 8.75374 17.3847 9.0848 15.7204C9.41587
            14.056 10.233 12.5272 11.433
            11.3273C12.6329 10.1273 14.1617 9.31015 15.8261 8.97909C17.4904
            8.64803 19.2156 8.81794 20.7834
            9.46734C22.3512 10.1167 23.6912 11.2165 24.634 12.6274C25.5767
            14.0384 26.0799 15.6973 26.0799
            17.3942C26.0799 19.6698 25.176 21.8521 23.5669 23.4612C21.9579
            25.0703 19.7755 25.9742 17.4999
            25.9742Z" fill="white"/>
            </svg>
          <h5 class="md:mt-5 absolute md:text-xl text-sm font-semibold text-white/100
          top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            Liverpool</h5>
        </router-link>
        <router-link :to="{ path: 'get-started' }" class="relative text-center col-span-1">
          <img class="object-cover object-center
          mx-auto" src="images/cities/london.jpg" alt="">
          <div class="absolute inset-0 bg-black opacity-50 mx-auto"></div>
          <svg xmlns="http://www.w3.org/2000/svg"
          class="mt-6 absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 hidden md:block
          pb-4" width="35" height="46" viewBox="0 0 35 46" fill="none">
            <path d="M17.4999 0.834229C13.108 0.834229 8.89585 2.57894 5.79025
            5.68454C2.68465 8.79014 0.939941 13.0022 0.939941 17.3942C0.939941 27.5642
            10.4199 39.5042 15.0299 44.7242C15.3396 45.0737 15.7199 45.3535 16.1456
            45.5451C16.5714 45.7367 17.033 45.8358 17.4999 45.8358C17.9669 45.8358 18.4285
            45.7367 18.8542 45.5451C19.28 45.3535 19.6603 45.0737 19.9699 44.7242C24.5899
            39.5042 34.0599 27.5642 34.0599 17.3942C34.0599 15.2195 33.6316 13.0661 32.7994
            11.057C31.9672 9.04784 30.7474 7.22228 29.2096 5.68454C27.6719 4.1468 25.8463 2.927
            23.8372 2.09478C21.828 1.26257 19.6746 0.834229 17.4999 0.834229ZM17.4999 25.9742C15.803
            25.9742 14.1441 25.471 12.7332 24.5282C11.3222 23.5855 10.2225 22.2454 9.57306
            20.6777C8.92366 19.1099 8.75374 17.3847 9.0848 15.7204C9.41587
            14.056 10.233 12.5272 11.433
            11.3273C12.6329 10.1273 14.1617 9.31015 15.8261 8.97909C17.4904
            8.64803 19.2156 8.81794 20.7834
            9.46734C22.3512 10.1167 23.6912 11.2165 24.634 12.6274C25.5767
            14.0384 26.0799 15.6973 26.0799
            17.3942C26.0799 19.6698 25.176 21.8521 23.5669 23.4612C21.9579
            25.0703 19.7755 25.9742 17.4999
            25.9742Z" fill="white"/>
            </svg>
          <h5 class="md:mt-5 absolute md:text-xl text-sm font-semibold text-white/100
          top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            London</h5>
        </router-link>
        <router-link :to="{ path: 'get-started' }" class="relative text-center col-span-1">
          <img class="object-cover object-center
          mx-auto" src="images/cities/manchester.jpg" alt="">
          <div class="absolute inset-0 bg-black opacity-50 mx-auto"></div>
          <svg xmlns="http://www.w3.org/2000/svg"
          class="mt-6 absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 hidden md:block
          pb-4" width="35" height="46" viewBox="0 0 35 46" fill="none">
            <path d="M17.4999 0.834229C13.108 0.834229 8.89585 2.57894 5.79025
            5.68454C2.68465 8.79014 0.939941 13.0022 0.939941 17.3942C0.939941 27.5642
            10.4199 39.5042 15.0299 44.7242C15.3396 45.0737 15.7199 45.3535 16.1456
            45.5451C16.5714 45.7367 17.033 45.8358 17.4999 45.8358C17.9669 45.8358 18.4285
            45.7367 18.8542 45.5451C19.28 45.3535 19.6603 45.0737 19.9699 44.7242C24.5899
            39.5042 34.0599 27.5642 34.0599 17.3942C34.0599 15.2195 33.6316 13.0661 32.7994
            11.057C31.9672 9.04784 30.7474 7.22228 29.2096 5.68454C27.6719 4.1468 25.8463 2.927
            23.8372 2.09478C21.828 1.26257 19.6746 0.834229 17.4999 0.834229ZM17.4999 25.9742C15.803
            25.9742 14.1441 25.471 12.7332 24.5282C11.3222 23.5855 10.2225 22.2454 9.57306
            20.6777C8.92366 19.1099 8.75374 17.3847 9.0848 15.7204C9.41587
            14.056 10.233 12.5272 11.433
            11.3273C12.6329 10.1273 14.1617 9.31015 15.8261 8.97909C17.4904
            8.64803 19.2156 8.81794 20.7834
            9.46734C22.3512 10.1167 23.6912 11.2165 24.634 12.6274C25.5767
            14.0384 26.0799 15.6973 26.0799
            17.3942C26.0799 19.6698 25.176 21.8521 23.5669 23.4612C21.9579
            25.0703 19.7755 25.9742 17.4999
            25.9742Z" fill="white"/>
            </svg>
          <h5 class="md:mt-5 absolute md:text-xl text-sm font-semibold text-white/100
          top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          Manchester</h5>
          </router-link>
      </div>
    </div>
  
  </section>
  <CarouselSlider/>
  <FaqPage/>
  <section>
    <div class="bg-blue-200 ">
      <div class="md:py-12 md:px-24 px-4 py-14">
        <h4 class="text-white font-bold md:text-4xl text-xl text-center">
          Find the best cosmetic surgery clinics in your area.
          <router-link :to="{ path: 'get-started' }">
            <span class="underline">Get Started</span>
          </router-link>
        </h4>
      </div>
    </div>
  </section>
   <FooterPage/>
   </div>
  </template>
  
  <script>
  import FooterPage from './FooterPage.vue';
  import FaqPage from './FaqPage.vue';
  import CarouselSlider from './CarouselSlider.vue';
  import HeaderPage from './HeaderPage.vue';
  
  export default {
    name: 'HomePage',
    components: {
      FooterPage,
      FaqPage,
      CarouselSlider,
      HeaderPage,
    },
    data() {
      return {
        postalCode: '',
        errorMessage: '',
      };
    },
    methods: {
      submitForm() {
        const ukPostalCodePattern = /^[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]?\s?[0-9][A-Za-z]{2}$/;
        // Validate the postal code against the custom pattern
        if (!ukPostalCodePattern.test(this.postalCode)) {
          this.errorMessage = 'Please enter a valid UK postal code';
          return; // Prevent form submission
        }
        // console.log('Form submitted');
        console.log('Form submitted');
        console.log('Postal Code:', this.postalCode);
        // Clear the error message if validation passes
        this.errorMessage = '';
        this.$router.push({ name: 'get-started', query: { postalCode: this.postalCode } });
      },
      // scrollToForm() {
      //   // Get the form section by id
      //   const formSection = document.getElementById('formSection');
  
      //   // Scroll to the form section
      //   if (formSection) {
      //     formSection.scrollIntoView({ behavior: 'smooth' });
      //   }
      // },
    },
  };
  </script>
  