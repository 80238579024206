<template>
    <HeaderPage/>
    <section class="relative md:px-20 lg:px-32 md:py-20 py-10 mx-auto overflow-hidden">
        <div class="text-lg  mx-auto space-y-8">
        <div> <span class="block md:text-2xl text-left text-blue-100 font-semibold tracking-wide pl-4 md:pl-0">
            Privacy & Policy</span>
            <div class="p-8 rounded-md">
                <div class="flex items-center space-x-2">
                <h2 class="md:text-xl font-bold">Introduction</h2>
            </div>
            <div class="space-y-4 md:text-left text-left text-10xl mt-6">
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    This is our privacy policy. It tells you how we collect and process
                    data received from you on our site. Please read the following carefully
                    to understand our views and practices regarding your personal
                    data and how we will treat it.</p>
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    If you have any comments on this privacy policy, please
                    email them to <a href="mailto:info@pivotal-one.co.uk">info@pivotal-one.co.uk</a>.</p>
            </div>
            </div>
            <div class="p-8 rounded-md">
                <div class="flex items-center space-x-2">
                <h2 class="md:text-xl font-bold">Who We Are</h2>
            </div>
            <div class="space-y-4 md:text-left text-left text-10xl mt-6">
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    Here are the details that the Regulation (EU) 2016/679 of the European
                    Parliament and of the Council of 27 April 2016 on the protection of natural
                    persons with regards to the processing of personal data and on the free
                    movement of such data, known as General Data Protection Regulation (GDPR)
                    says we have to give you as a ‘data controller’:</p>
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    If you have any comments on this privacy policy, please
                    email them to <a href="mailto:info@pivotal-one.co.uk">info@pivotal-one.co.uk</a>.</p>
                    <ul class="md:text-base text-blue-100 md:leading-8 font-medium md:ml-10 ml-6"
                    style="list-style: inherit;">
                    <li class="md:text-base">Our site address is www.cosmeticsurgerynearme.uk</li>
                    <li class="md:text-base">The site is a trading style of Pivotal One Ltd</li>
                    <li class="md:text-base">Our registered address is <address>112 Bolling Road,
                        Ilkley, LS29 8PN.</address></li>
                    <li class="md:text-base">Our Data Protection Officer can be contacted
                        at <a href="mailto:info@pivotal-one.co.uk">info@pivotal-one.co.uk</a>.</li>
                </ul>
            </div>
            </div>
            <div class="p-8 rounded-md">
                <div class="flex items-center space-x-2">
                <h2 class="md:text-xl font-bold">What We May Collect</h2>
            </div>
            <div class="space-y-4 md:text-left text-left text-10xl mt-6 whitespace-normal">
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    We may collect and process the following data about you: </p>
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    If you have any comments on this privacy policy, please
                    email them to <a href="mailto:info@pivotal-one.co.uk">info@pivotal-one.co.uk</a>.</p>
                    <ul class="md:text-base text-blue-100 md:leading-8 font-medium md:ml-10 ml-6"
                    style="list-style: inherit;">
                    <li class="md:text-base">
                        Information you put into forms on our site at any time</li>
                    <li class="md:text-base">
                        A record of any correspondence between us</li>
                    <li class="md:text-base">
                        Details of transactions you carry out through our site</li>
                    <li class="md:text-base">
                        Information about your computer (e.g. your IP address, browser,
                        operating system, etc.) for system administration and to report aggregate
                        information to our advertisers</li>
                </ul>
                <p class=" md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    Under GDPR we will ensure that your personal data is processed lawfully,
                    fairly, and transparently, without adversely affecting your rights.
                    We will only process your personal data if at least one of the following
                    basis applies:</p>
                    <ul class="md:text-base text-blue-100 md:leading-8 font-medium md:ml-10 ml-6"
                    style="list-style: inherit;">
                    <li class="md:text-base">
                        You have given consent to the processing of your personal data for one
                        or more specific purposes;</li>
                    <li class="md:text-base">
                        Processing is necessary for the performance of a contract to which you
                        are a party or in order to take steps at the request of you prior to
                        entering into a contract;</li>
                    <li class="md:text-base">
                        processing is necessary for compliance with a legal obligation to which
                        we are subject;</li>
                    <li class="md:text-base">
                        processing is necessary to protect the vital interests of you or of
                        another natural person;</li>
                    <li class="md:text-base">
                        processing is necessary for the performance of a task carried out in
                        the public interest or in the exercise of official authority vested
                        in the controller; and/or</li>
                    <li class="md:text-base">
                        processing is necessary to protect the vital interests of you or of
                        another natural person;</li>
                </ul>
                    <ul class="md:text-base text-blue-100 md:leading-8 font-medium md:ml-10 ml-6"
                    style="list-style: inherit;">
                    <li class="md:text-base">
                        You have given consent to the processing of your personal data for one
                        or more specific purposes;</li>
                    <li class="md:text-base">
                        Processing is necessary for the performance of a contract to which you
                        are a party or in order to take steps at the request of you prior to
                        entering into a contract;</li>
                    <li class="md:text-base">
                        processing is necessary for compliance with a legal obligation to which
                        we are subject;</li>
                    <li class="md:text-base">
                        processing is necessary to protect the vital interests of you or of
                        another natural person;</li>
                    <li class="md:text-base">
                        processing is necessary for the performance of a task carried out in
                        the public interest or in the exercise of official authority vested
                        in the controller; and/or</li>
                    <li class="md:text-base">
                        processing is necessary to protect the vital interests of you or of
                        another natural person;</li>
                </ul>
            </div>
            </div>
            <div class="p-8 rounded-md">
                <div class="flex items-center space-x-2">
                <h2 class="md:text-xl font-bold">Cookies</h2>
            </div>
            <div class="space-y-4 md:text-left text-left text-10xl mt-6">
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    All Cookies used by and on our website are used in accordance with
                    current English and EU Cookie Law. The site uses cookies or similar
                    technology to collect information about your access to the site.
                    Cookies are pieces of information that include a unique reference code
                    that a website transfers to your device to store and sometimes track
                    information about you.</p>
                    <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    A few of the cookies we use last only for the duration of your web
                    session and expire when you close your browser. Other cookies are used
                    to remember you when you return to the site and will last for longer.
                    All cookies used on our site are set by us.</p>
                    <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    Most computer and some mobile web browsers automatically accept cookies but,
                    if you prefer, you can change your browser to prevent that or to notify you
                    each time a cookie is set. You can prevent the setting of cookies by adjusting
                    the settings on your browser. Please note however, that by blocking or deleting
                    cookies you may not be able to take full advantage of the site.</p>
                    <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    Our cookies will be used for:</p>
                    <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    Essential session management</p>
                    <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    Creating a specific log-in session for a user of the site in order that the
                    site remembers that a user is logged in and that their page requests are
                    delivered in an effective, secure and consistent manner;</p>
                    <ul class="md:text-base text-blue-100 md:leading-8 font-medium md:ml-10 ml-6"
                    style="list-style: inherit;">
                    <li class="md:text-base">
                        Recognising when a user of the site has visited before allowing us to
                        identify the number of unique users we receive to the site and make sure
                        we have enough capacity for the number of users that we get;</li>
                    <li class="md:text-base">
                        Recognising if a visitor to the site is registered with us in any way;</li>
                    <li class="md:text-base">
                        We may also log information from your computer including the existence
                        of cookies,  your IP address and information about your browser program
                        in order to allow us to diagnose problems, administer and track
                        your usage of our site.</li>
                </ul>
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    Functionality</p>
                    <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    customising elements of the promotional layout and/or content of
                    the pages of the site.</p>
                    <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    Performance and measurement</p>
                    <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    Collecting statistical information about how our users use the site
                    so that we can improve the site and learn which parts are most popular
                    to users.</p>
            </div>
            </div>
            <div class="p-8 rounded-md">
                <div class="flex items-center space-x-2">
                <h2 class="md:text-xl font-bold">How we use what we collect</h2>
            </div>
            <div class="space-y-4 md:text-left text-left text-10xl mt-6">
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    We use information about you to:</p>
                    <ul class="md:text-base text-blue-100 md:leading-8 font-medium md:ml-10 ml-6"
                    style="list-style: inherit;">
                    <li class="md:text-base">Present site content effectively to you.</li>
                    <li class="md:text-base">Provide information, products and services
                        that you request, or (with your consent) which we think may
                        interest you.</li>
                    <li class="md:text-base">Carry out our contracts with you.</li>
                    <li class="md:text-base">Allow you to use our interactive services
                        if you want to.</li>
                    <li class="md:text-base">Tell you our charges.</li>
                    <li class="md:text-base">Tell you about other goods and services that might
                        interest you.</li>
                    <li class="md:text-base">If you are already our customer, we will only contact
                        you electronically about things similar to what was previously
                        sold to you.</li>
                    <li class="md:text-base">If you are a new customer, you will only be contacted
                        if you complete our online form.</li>
                </ul>
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    Please note: We don’t identify individuals to our advertisers,
                    but we do give them aggregate information to help them reach
                    their target audience, and we may use information we
                    have collected to display advertisements to that audience.</p>
                    <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    In addition, if you don’t want us to use your personal data for any
                    of the other reasons set out in this section, you should not submit
                    any personal information to us and, if you have done so already,
                    you can let us know that you wish us to stop using your data by
                    contacting us at , and we will delete your data from our systems.
                    However, you acknowledge this will limit our ability to provide
                    the best possible services to you.</p>
                    <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    In some cases, the collection of personal data may be a statutory or contractual
                    requirement, and we will be limited in the services we can provide you
                    if you don’t provide your personal data in these cases.</p>
            </div>
            </div>
            <div class="p-8 rounded-md">
                <div class="flex items-center space-x-2">
                <h2 class="md:text-xl font-bold">Where we store your data</h2>
            </div>
            <div class="space-y-4 md:text-left text-left text-10xl mt-6">
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    We may transfer your collected data to storage outside the European Economic Area (EEA).
                    It may be processed outside the EEA to fulfil your order and deal with payment.</p>
                    <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    We may transfer your collected data to storage outside the European Economic Area (EEA).
                    It may be processed outside the EEA to fulfil your order and deal with payment.</p>
                    <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                        Payment will be encrypted. If we give you a password, you must keep it
                        confidential. Please don’t share it. Although we try to provide protection,
                        we cannot guarantee complete security for your data, and you take the risk
                        that any sending of that data turns out to be not secure despite our efforts.
                        We only keep your personal data for as long as we need to in order to use it
                        as described above in the section "How we use what we collect", and/or for as
                        long as we have your permission to keep it. In any event, we will conduct a
                        review to ascertain whether we need to keep your personal data.
                        Your personal data will be deleted if we no longer need it.</p>

            </div>
            </div>
            <div class="p-8 rounded-md">
                <div class="flex items-center space-x-2">
                <h2 class="md:text-xl font-bold">Disclosing your information</h2>
            </div>
            <div class="space-y-4 md:text-left text-left text-10xl mt-6">
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    We are allowed to disclose your information in the following cases:</p>
                    <ul class="md:text-base text-blue-100 md:leading-8 font-medium md:ml-10 ml-6"
                    style="list-style: inherit;">
                    <li class="md:text-base">If we want to sell our business, or our company, we can disclose it to the potential buyer.</li>
                    <li class="md:text-base">We can disclose it if we have a legal obligation to do so, or in order to protect other people’s property, safety or rights.</li>
                    <li class="md:text-base">We can exchange information with others to protect against fraud or credit risks.</li>
                </ul>
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    We may contract with third parties to supply services to you on our behalf. These may include payment processing, search engine facilities, advertising and marketing. In some cases, the third parties may require access to some or all of your data.</p>
                    <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                        Where any of your data is required for such a purpose, we will take all reasonable steps to ensure that your data will be handled safely, securely, and in accordance with your rights, our obligations, and the obligations of the third party under GDPR and the law.</p>
            </div>
            </div>
            <div class="p-8 rounded-md">
                <div class="flex items-center space-x-2">
                <h2 class="md:text-xl font-bold">Your rights</h2>
            </div>
                 <div class="space-y-4 md:text-left text-left text-10xl mt-6">
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    You can ask us not to use your data for marketing. You can do this by ticking the relevant boxes on our forms, or by contacting us at any time at Fairfax House, 38 The Grove, Ilkley, England, LS29 9EE. Email<a href="mailto:info@pivotal-one.co.uk">info@pivotal-one.co.uk</a>.</p>
                    <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                        Under the GDPR, you have the right to:</p>
                    <ul class="md:text-base text-blue-100 md:leading-8 font-medium md:ml-10 ml-6"
                    style="list-style: inherit;">
                    <li class="md:text-base">request access to, deletion of or correction of, your personal data held by us at no cost to you;</li>
                    <li class="md:text-base">request that your personal data be transferred to another person (data portability);</li>
                    <li class="md:text-base">be informed of what data processing is taking place;</li>
                    <li class="md:text-base">restrict processing;</li>
                    <li class="md:text-base">to object to processing of your personal data; and</li>
                    <li class="md:text-base">complain to a supervisory authority.</li>
                </ul>
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    You also have rights with respect to automated decision-making and profiling as set out in section 11 below. To enforce any of the foregoing rights or if you have any other questions about our site or this Privacy Policy, please contact us at <a href="mailto:info@pivotal-one.co.uk">info@pivotal-one.co.uk</a>.</p> 
                 </div>
                 
            </div>
            <div class="p-8 rounded-md">
                <div class="flex items-center space-x-2">
                <h2 class="md:text-xl font-bold">Links to other sites</h2>
            </div>
                 <div class="space-y-4 md:text-left text-left text-10xl mt-6">
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    Please note that our terms and conditions and our policies will not apply to other websites that you get to via a link from our site. We have no control over how your data is collected, stored or used by other websites and we advise you to check the privacy policies of any such websites before providing any data to them.</p>
                 </div> 
            </div>
            <div class="p-8 rounded-md">
                <div class="flex items-center space-x-2">
                <h2 class="md:text-xl font-bold">Changes</h2>
            </div>
                 <div class="space-y-4 md:text-left text-left text-10xl mt-6">
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    If we change our Privacy Policy, we will post the changes on this page. If we decide to, we may also email you.</p>
                 </div> 
            </div>
            <div class="p-8 rounded-md">
                <div class="flex items-center space-x-2">
                <h2 class="md:text-xl font-bold">Automated Decision-Making and Profiling</h2>
            </div>
                 <div class="space-y-4 md:text-left text-left text-10xl mt-6">
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    In the event that we use personal data for the purposes of automated decision-making and those decisions have a legal (or similarly significant effect) on you, you have the right to challenge to such decisions under GDPR, requesting human intervention, expressing their own point of view, and obtaining an explanation of the decision from us.</p>
                    <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                        The right described in section 11.1 does not apply in the following circumstances:uesting human intervention, expressing their own point of view, and obtaining an explanation of the decision from us.</p>
                        <ul class="md:text-base text-blue-100 md:leading-8 font-medium md:ml-10 ml-6"
                    style="list-style: inherit;">
                    <li class="md:text-base">the decision is necessary for the entry into, or performance of, a contract between the you and us;</li>
                    <li class="md:text-base">request that your personal data be transferred to another person (data portability);</li>
                    <li class="md:text-base">the decision is authorised by law; or</li>
                    <li class="md:text-base">you have given you explicit consent.</li>
                </ul>
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    Where we use your personal data for profiling purposes, the following shall apply:</p>
                    <ul class="md:text-base text-blue-100 md:leading-8 font-medium md:ml-10 ml-6"
                    style="list-style: inherit;">
                    <li class="md:text-base">Clear information explaining the profiling will be provided, including its significance and the likely consequences;</li>
                    <li class="md:text-base">Appropriate mathematical or statistical procedures will be used;</li>
                    <li class="md:text-base">Technical and organisational measures necessary to minimise the risk of errors and to enable such errors to be easily corrected shall be implemented; and</li>
                    <li class="md:text-base">All personal data processed for profiling purposes shall be secured in order to prevent discriminatory effects arising out of profiling.</li>
                </ul>
                 </div> 
            </div>
            <div class="p-8 rounded-md">
                <div class="flex items-center space-x-2">
                <h2 class="md:text-xl font-bold">Your rights</h2>
            </div>
                 <div class="space-y-8 md:text-left text-left text-10xl mt-6">
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    The Parties will use their best efforts to negotiate in good faith and settle any dispute that may arise out of or relate to this Privacy Policy or any breach of it.</p>
                    <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                        If any such dispute cannot be settled amicably through ordinary negotiations between the parties, or either or both is or are unwilling to engage in this process, either party may propose to the other in writing that structured negotiations be entered into with the assistance of a fully accredited mediator before resorting to litigation.</p>
                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                    If the parties are unable to agree upon a mediator, or if the mediator agreed upon is unable or unwilling to act and an alternative mediator cannot be agreed, any party may within 14 days of the date of knowledge of either event apply to LawBite to appoint a mediator under the LawBite Mediation Procedure.</p> 
                    <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                        Within 14 days of the appointment of the mediator (either by mutual agreement of the parties or by LawBite in accordance with their mediation procedure), the parties will meet with the mediator to agree the procedure to be adopted for the mediation, unless otherwise agreed between the parties and the mediator. All negotiations connected with the relevant dispute(s) will be conducted in confidence and without prejudice to the rights of the parties in any further proceedings.</p> 
                        <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                            If the parties agree on a resolution of the dispute at mediation, the agreement shall be reduced to writing and, once signed by the duly authorised representatives of both parties, shall be final and binding on them.</p> 
                            <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                                If the parties fail to resolve the dispute(s) within 60 days (or such longer term as may be agreed between the parties) of the mediator being appointed, or if either party withdraws from the mediation procedure, then either party may exercise any right to seek a remedy through arbitration by an arbitrator to be appointed by LawBite under the Rules of the LawBite Arbitration Scheme.</p> 
                                <p class="md:text-base text-blue-100 md:text-left text-left text-10xl md:leading-8 font-medium">
                                    Any dispute shall not affect the parties’ ongoing obligations under this Privacy Policy.</p> 
                            
                 </div>
                 
            </div>
        </div>
        </div>
    </section>
    <FooterPage/>
</template>

<script>
import HeaderPage from './HeaderPage.vue';
import FooterPage from './FooterPage.vue';

export default {
  components: {
    HeaderPage,
    FooterPage,
  },
};
</script>
