<template>
  <div class="md:px-24 py-14 px-4 sm:py-32 lg:py-32 mx-auto">
    <div class="lg:grid lg:grid-cols-12 gap-12">
      <div class="col-span-6">
          <img class="w-full lg:flex hidden" src="images/Section → Figure.png" alt="">
      </div>
      <div class="col-span-6">
          <h5 class="text-black font-semibold text-xl md:text-xl w-full text-center
          md:text-left px-4 md:px-0">
          Our team is on hand to answer any questions.
        </h5>
        <p class="text-blue-100 font-medium text-sm md:text-12xl
        mt-3 mb-8 text-center md:text-left px-6 md:px-0">
          Below are just a few of the most frequently asked questions:
        </p>
      <!-- FAQ child component -->
      <div v-for="(faq, index) in faqItems" :key="index" class="md:mt-1 mt-3">
        <div class="bg-blue-100 p-5 text-white font-medium
        text-sm md:text-sm flex justify-between items-center  cursor-pointer"
        :class="{ 'bg-blue-100': faq.isOpen }"
        @click="toggleAnswer(faq)" @keydown.enter="toggleAnswer(faq)">
          <div class="cursor-pointer">{{ faq.q }}</div>
          <!-- Toggle button for each question -->
          <button class="flex" @click="toggleAnswer(faq)" @keydown.enter="toggleAnswer(faq)">
              <span v-if="faq.isOpen"
              @click="toggleAnswer(faq)" @keydown.enter="toggleAnswer(faq)"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
              </svg>
              </span>
              <svg v-else @click="toggleAnswer(faq)" @keydown.enter="toggleAnswer(faq)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
              </svg>

              <!-- <img v-else @click="toggleAnswer(faq)" @keydown.enter="toggleAnswer(faq)" src="images/icons/+.svg" alt=""> -->
            </button>
        </div>
        <div class="p-5" v-show="faq.isOpen">{{ faq.a }}</div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
data() {
  return {
    faqItems: [
      {
        q: 'What is the right age for cosmetic surgery?',
        a: 'The ideal age for cosmetic surgery varies depending on the procedure and individual factors. Generally, most surgeons recommend waiting until the patient’s facial and body features are fully developed. This often means waiting until the late teens or early twenties for procedures like rhinoplasty or breast augmentation. However, age alone is not the only factor; overall health, emotional maturity, and realistic expectations also play crucial roles in determining suitability for cosmetic surgery.',
        isOpen: false,
      },
      {
        q: 'How much does cosmetic surgery cost?',
        a: 'The cost of cosmetic surgery varies based on the type of procedure, the surgeon’s expertise, geographical location, and facility fees. During your free consultation, the surgeon can provide a detailed breakdown of costs, including surgeon fees, anesthesia, facility charges, and any post-operative care.',
        isOpen: false,
      },
      {
        q: 'Are there financing options available for cosmetic surgery?',
        a: 'All of our cosmetic surgery practices offer financing options to make procedures more accessible. Patients can explore financing through third-party medical financing companies or discuss flexible payment plans directly with the practices during your free consultation.',
        isOpen: false,
      },
      {
        q: 'What is the typical recovery time for cosmetic surgery?',
        a: 'Recovery times depend on the specific procedure and individual healing factors. While some non-invasive treatments may require minimal downtime, more extensive surgeries may necessitate several weeks for a full recovery. Adhering to post-operative care instructions is crucial for a smooth and successful recovery.',
        isOpen: false,
      },
      {
        q: 'What are the potential risks and complications associated with cosmetic surgery?',
        a: 'Like any surgery, cosmetic procedures carry inherent risks. These can include infection, bleeding, scarring, and anesthesia-related complications. It’s essential for patients to be aware of potential risks and thoroughly discuss them with their surgeon during the consultation.',
        isOpen: false,
      },
    ],
  };
},
//   methods: {
//     toggleAnswer(faq) {
//       faq.isOpen = !faq.isOpen;
//     },
//   },
methods: {
  toggleAnswer(clickedFaq) {
    const updatedFaqItems = this.faqItems.map((faq) => ({
      ...faq,
      isOpen: faq === clickedFaq ? !faq.isOpen : false,
    }));

    this.faqItems = updatedFaqItems;
  },
},
};
</script>
