<template>
  <header class="bg-blue-100">
<div class="flex items-center justify-between flex-wrap mx-auto px-20 py-6 md:py-6 lg:px-24">
  <router-link class="text-blue-100" to="/">
    <img class="w-full" src="images/logos/cosmetic-surgery-logo.svg" alt="cosmetic-surgery-logo">
  </router-link>
  <div class="hidden w-full lg:flex md:flex-row md:w-auto items-center space-x-8 ml-auto">
<div class="text-white font-medium"> The UK’s Best Cosmetic Clinics <br>
  <span class="text-right text-green-100 font-semibold">Book a FREE Consultation Today</span>
</div>
<div>
  <img class="w-32" src="images/logos/stars-5.svg" alt="trustpilot-review-star">
</div>
</div>
</div>
</header>
</template>
