import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import vueGtag from 'vue-gtag'
import HomePage from './components/HomePage.vue';
import GetStarted from './components/GetStarted.vue';
import ThankYou from './components/ThankYou.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue';
import TermsOfWebsite from './components/TermsOfWebsite.vue';

const routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/get-started',
    name: 'get-started',
    component: GetStarted,
  },
  {
    path: '/thankyou',
    name: 'ThankYou',
    component: ThankYou,
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/terms',
    name: 'TermsOfWebsite',
    component: TermsOfWebsite,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);
app.use(router);
app.use(vueGtag,{
  config: {id: 'G-PJWK6Q7Z4M'},
});
app.mount('#app');

const $ = global.jQuery;
window.$ = $;
global.jQuery = require('jquery');
