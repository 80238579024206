<template>
    <HeaderPage/>
    <section class="md:px-20 py-16 mx-auto px-6">
        <div class="text-center max-w-7xl">
          <h2 class="text-3xl font-semibold tracking-tight text-blue-100 sm:text-17xl">
            Thank you for your enquiry
        </h2>
        <!-- <div class=" flex justify-center">
            <p class="mt-4 text-18xl font-medium leading-6 text-gray-600 max-w-3xl">
              You have been matched with Signature Clinic, one of the leading groups of cosmetic surgery clinics in the UK. A member of the Signature Clinic team will call you soon to book your free consultation with an experienced surgeon who specialises in your chosen procedure.
        </p>
        </div> -->
        </div>
        <!--  -->
        <PriceList/>
        <!--  -->
        <div class="grid lg:grid-cols-12 md:mt-4 gap-12 py-8">
            <div class="col-span-6 mt-4">
                <img class="h-auto max-w-full"
                src="images/logos/Signature-Homepage-Featured-Image.jpg"
                alt="Signature-Homepage-Featured-Image">
            </div>
        <div class="col-span-6">
            <ul>
                <h2 class="text-blue-100 md:text-3xl text-3xl font-semibold lg:pt-2">
                    Enjoy peace of mind with GMC Registered surgeons</h2>
                <li class="pb-1.5 pt-6 lg:pt-0">
                    <div class="flex items-center space-x-3">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                            class="w-8 h-8 text-green-100">
                            <path stroke-linecap="round" stroke-linejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </span>
                        <div class="text-18xl lg:text-12xl text-blue-100 font-medium">
                        <span>Access to the exclusive prices with no hidden fees</span>
                    </div>
                </div>
            </li>
                <li class="py-1.5">
                    <div class="flex items-center space-x-3">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                            class="w-8 h-8 text-green-100">
                            <path stroke-linecap="round" stroke-linejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </span>
                        <div class="text-18xl lg:text-12xl text-blue-100 font-medium">
                        <span>Enjoy peace of mind with GMC Registered surgeons</span>
                    </div>
                </div>
            </li>
                <li class="py-1.5">
                    <div class="flex items-center space-x-3">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                            class="w-8 h-8 text-green-100">
                            <path stroke-linecap="round" stroke-linejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </span>
                        <div class="text-18xl lg:text-12xl text-blue-100 font-medium">
                        <span>0% interest-free finance available on all treatments</span>
                    </div>
                </div>
            </li>
                <li class="py-1.5">
                    <div class="flex items-center space-x-3">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                            class="w-8 h-8 text-green-100">
                            <path stroke-linecap="round" stroke-linejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </span>
                        <div class="text-18xl lg:text-12xl text-blue-100 font-medium">
                        <span>Free consultations with no obligation to proceed</span>
                    </div>
                </div>
            </li>
                <li class="py-1.5">
                    <div class="flex items-center space-x-3">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                            class="w-8 h-8 text-green-100">
                            <path stroke-linecap="round" stroke-linejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </span>
                        <div class="text-18xl lg:text-12xl text-blue-100 font-medium">
                        <span>Online consultations are now also available</span>
                    </div>
                </div>
            </li>
            <li class="py-1.5">
                    <div class="flex items-center space-x-3">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                            class="w-8 h-8 text-green-100">
                            <path stroke-linecap="round" stroke-linejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </span>
                        <div class="text-18xl lg:text-12xl text-blue-100 font-medium">
                        <span>Free aftercare and follow-up appointments for life</span>
                    </div>
                </div>
            </li>

        </ul>
        </div>
        </div>
        <div class="md:py-4">
                <h3 class="md:text-4xl text-3xl text-blue-100 md:text-center md:py-8 font-semibold">
                    What Happens Next</h3>
                <div class="mx-auto max-w-7xl px-6 lg:px-8 py-4">
                    <div class="mx-auto grid max-w-2xl grid-cols-1
                    gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
                  <div v-for="item in timeline" :key="item.name">
                  <time :step="item.step"
                  class="flex items-center text-sm font-semibold leading-6 text-green-100">
                  <svg viewBox="0 0 4 4" class="mr-4 h-1 w-1 flex-none" aria-hidden="true">
              <circle cx="2" cy="2" r="2" fill="currentColor" />
            </svg>
            {{ item.date }}
            <div class="absolute -ml-2 h-px w-screen -translate-x-full
            bg-gray-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto
            lg:flex-auto lg:translate-x-0" aria-hidden="true"/>
          </time> 
          <p class="mt-1 text-10xl leading-7 text-blue-100">{{ item.description }}</p>
        </div>
      </div>
    </div>
            </div>
    </section>
    <carousel-slider></carousel-slider>
    <FooterPage/>
</template>
<script>
import HeaderPage from './HeaderPage.vue';
import FooterPage from './FooterPage.vue';
import CarouselSlider from './CarouselSlider.vue';
import PriceList from './PriceList.vue';




export default {
  components: {
    HeaderPage,
    FooterPage,
    CarouselSlider,
    PriceList,
  },
//   Event snippet for Lead Generated conversion page 
//   mounted(){
//     gtag('event', 'conversion', {'send_to': 'AW-11414924289/vR_BCP6YyvcYEIHYh8Mq'});
//   },
  mounted() {
    this.$gtag.event('conversion', {
      send_to: 'AW-11414924289/vR_BCP6YyvcYEIHYh8Mq',
    });
  },
  data() {
    return {
      timeline: [
        {
          description: 'We have received your details and matched you to Signature Clinic.',
          date: 'Step 1',
          step: 'Step 1',
        },
        {
          description: 'Signature Clinic will give you a call to arrange your FREE telephone consultation and discuss your requirements.',
          date: 'Step 2',
          step: 'Step 2',
        },
        {
          description: 'Once you have your free consultation you can make a decision as to whether you want to proceed or not.',
          date: 'Step 3',
          step: 'Step 3',
        },
        {
          description: 'If you are happy with everything Signature Clinic will arrange all the further details with you and further steps for booking you in.',
          date: 'Step 4',
          step: 'Step 4',
        },
      ],
    };
  },
};
</script>
