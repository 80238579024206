<template>
    <HeaderPage/>
        <section class="py-12">
            <heyflow-wrapper
            flow-id="clinical-surgery-near-me"
            dynamic-height style-config='{"width": "100%"}'>
          </heyflow-wrapper>
        </section>
</template>

<script>
import HeaderPage from './HeaderPage.vue';

export default {
  components: {
    HeaderPage,
  },
  data() {
    return {
      postalCode: null,
    };
  },
  mounted() {
    const { postalCode } = this.$route.query;
    this.postalCode = postalCode;
    // Access the postal code from the URL
    // const { postalCode } = this.$route.query;
    // const { postalCode } = this.$route.query;
    // Now 'postalCode' contains the value from the URL, and you can use it as needed
  },
};
</script>
